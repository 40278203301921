.con{
	background: #994BFF;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 1px 2px;
    display: flex;
}

.fix{
	margin: 0 0 0 auto !important; 
	display: flex;

	align-items: center;
	justify-content: center;
}
.fix span{
	align-items: center;
    justify-content: center;
    display: flex;
	font-size: 18px;
	cursor: pointer;
}
:global .s{
	background: #26CAAC;
}
:global .e{
	background: #F44336;
}
:global .w{
	background:  #FF9800;
}