.target{
	/*background: #D5E696;*/
	z-index: 10000;
	width: 100%;
	max-width: 380px;
	position: fixed;
	top: 0;
	right: 0;

}
.title{
	    background: #fff;
    font-weight: bold;
    padding: 0 5px;
    font-size: 12px;
}